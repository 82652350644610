<template>
    <div :class="CSSClasses" class="content-feed-block">
        <template v-if="size === '100'">
            <template v-if="numFeedItems < 3">
                <div v-for="item in contentFeedItems" :key="item?.id" class="half-column">
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="item"
                        :overrideImage="item?.overrideImage"
                        size="50"
                    />
                </div>
            </template>
            <template v-if="numFeedItems === 3">
                <div v-for="item in contentFeedItems" :key="item?.id" class="third-column">
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="item"
                        :overrideImage="item?.overrideImage"
                        size="33"
                    />
                </div>
            </template>
            <template v-if="numFeedItems === 4">
                <div class="half-column">
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[0]"
                        :overrideImage="contentFeedItems[0]?.overrideImage"
                        size="50"
                    />
                </div>

                <div class="half-column">
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[1]"
                        :overrideImage="contentFeedItems[1]?.overrideImage"
                        size="50"
                        orientation="wide"
                    />
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[2]"
                        :overrideImage="contentFeedItems[2]?.overrideImage"
                        size="50"
                        orientation="wide"
                    />
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[3]"
                        :overrideImage="contentFeedItems[3]?.overrideImage"
                        size="50"
                        orientation="wide"
                    />
                </div>
            </template>
            <template v-if="numFeedItems === 5">
                <div class="half-column">
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[0]"
                        :overrideImage="contentFeedItems[0]?.overrideImage"
                        size="50"
                    />
                </div>

                <div class="half-column">
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[1]"
                        :overrideImage="contentFeedItems[1]?.overrideImage"
                        size="50"
                        orientation="wide"
                        hideImage
                    />
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[2]"
                        :overrideImage="contentFeedItems[2]?.overrideImage"
                        size="50"
                        orientation="wide"
                        hideImage
                    />
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[3]"
                        :overrideImage="contentFeedItems[3]?.overrideImage"
                        size="50"
                        orientation="wide"
                        hideImage
                    />
                    <ContentCardBlock
                        :pageLanguage="pageLanguage"
                        :related="contentFeedItems[4]"
                        :overrideImage="contentFeedItems[4]?.overrideImage"
                        size="50"
                        orientation="wide"
                        hideImage
                    />
                </div>
            </template>
        </template>
        <template v-if="size === '50'">
            <ContentCardBlock
                v-for="item in contentFeedItems"
                :key="item?.id"
                :related="item"
                :overrideImage="item?.overrideImage"
                :size="size"
                :pageLanguage="pageLanguage"
                orientation="wide"
                :hideImage="!!(numFeedItems > 4)"
            />
        </template>
        <template v-if="size === '33' || size === '25'">
            <ContentCardBlock
                v-for="item in contentFeedItems"
                :key="item?.id"
                :related="item"
                :overrideImage="item?.overrideImage"
                :size="size"
                :pageLanguage="pageLanguage"
                hideImage
                hideDescription
            />
        </template>
    </div>
</template>

<script setup>
const props = defineProps({
    contentFeed: {
        type: Object,
    },
    size: {
        ...sizes,
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});

const contentFeedItems = computed(() => getContentFeedItems(props.contentFeed?.feed, props.contentFeed?.dynamic));
const numFeedItems = contentFeedItems?.value?.length;
const CSSClasses = computed(() => {
    return [`size-${props.size}`, `feed-items-${numFeedItems}`];
});
</script>

<style lang="scss" scoped>
.content-feed-block {
    &.size-100 {
        @include media-query(tablet-mw) {
            grid-template-columns: repeat(12, 1fr);
            display: grid;
            gap: var(--col-gutter);
        }

        .half-column {
            grid-column: span 6;
        }

        .third-column {
            grid-column: span 4;
        }
    }
}
</style>
